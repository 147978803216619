import { type FC } from 'react';

type FormLabelProps = {
  label: string;
  attribute: string;
  required: boolean;
};

const FormLabel: FC<FormLabelProps> = (props) => (
  <label
    htmlFor={props.attribute}
    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
  >
    {props.label}
    {props.required ? (
      <span className="p-contact__titleSupplement text-sm">( 必須 )</span>
    ) : (
      ''
    )}
  </label>
);

export default FormLabel;
